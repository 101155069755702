
@media only screen and (min-width: 992px) {
  .mainContainer { 
    width: 1080px!important;
    max-width: 1080px;

  }
}


.autocomplete-root {
  position: relative;
}

.autocomplete-dropdown-container {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 0 0 2px 2px;
  position: absolute;
  z-index: 1000;
  top: 100%;
  left: 0;
  right: 0;
}

.suggestion {
  background-color: #fff;
  color: gray;
  font-size: 90%;
  padding: 8px;
  text-align: left;
  cursor: pointer;
  z-index: 1000;
}

.suggestion:hover, .suggestion:active {
  background-color: #fafafa;
  cursor: pointer;
}

.message {
  position: fixed;
  left: 16px;
  margin-right: 16px;
  bottom: 16px;
  z-index: 9999;
}

.nav-tabs .nav-item {
  cursor: pointer;
}

.vertical-align {
  display: flex;
  align-items: center;
}

.correct-answer {
  color: #4a3;
  display: block;
  margin-top: 4px;
  font-size: 14px;
}

button ion-icon, a ion-icon {
  font-size: 110%; display: inline-block; vertical-align: middle; padding-right: 8px;
}

.navbar-toggler { padding: .25rem .25rem; border: 0; }
.navbar-brand { display: block; text-align: center; margin: auto; padding-right: 56px; }
button:focus.navbar-toggler { outline: 0; }


.btn-primary {
  border-color: #7400ae;
  background: #7400ae linear-gradient(180deg,#8926ba,#7400ae) repeat-x;
}

.ended {
  opacity: 0.95;
  background-color: #F7F7F7;
}

/* loopHQ css */

/*BANNER*/
.banner {
  background-color: #7400ae;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-close {
  border: 1px solid #fff;
  cursor: pointer;
  border-radius: 15px;
  height: 30px;
  min-width: 30px;
  max-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-content {
  margin: 0 20px;
}


@media only screen and (max-width: 600px) {
  .banner { 
    padding: 10px 40px!important;
    margin: 0!important;

  }

  .banner-close {
    position: absolute;
    right: 0;
    top: 0;
    right: 0;
    margin: 10px;


  }

  .banner-content {
padding: 0;
margin: 0;

  }

  .banner-content .navbar-text {
    padding: 0;
    margin: 0;
    font-size: 14px;
    line-height: 12px;
    
      }
      .banner-content .text-center {
        padding: 0;
        margin: 0;
        font-size: 12px;
        line-height: 10px;
        
          }
}



/* Navbar */

.navbar-toggler {
  padding: .25rem .25rem;
  border: 0;
}

.navbar-brand {
  display: block;
  text-align: center;
  margin: auto;
  padding-right: 56px;
}

button:focus.navbar-toggler {
  outline: 0;
}


/* align icons with text */

button ion-icon,
a ion-icon {
  font-size: 110%;
  display: inline-block;
  vertical-align: middle;
  padding-right: 8px;
  padding-bottom: 4px;
}


/* location settings menu */

.locationMenu .dropdown-toggle::after {
  display: none;
}

.locationMenu .btn {
  padding: 10px 14px 10px 10px;
  background: none;
  border-radius: 0;
}

.locationMenu .dropdown-toggle ion-icon {
  font-size: 200%;
}

/* Cards */
.card, .videoCard {
  border: 1px solid #eeeeee;
  border-radius: 5px;
  box-shadow: 4px 4px 10px rgba(0,0,0,0.2)!important;
  
}
.card h4 {
  font-weight: 400!important;
  line-height: 1.3;
  /*
  background-color: black;
  color: #fff; 
  display: inline;
  padding: 0.5rem;
  
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  */
}
.card p {
  line-height: 1.3;
  font-weight: 400!important;
  /*background-color: black;
  color: #fff; 
  display: inline;
  padding: 0.5rem;
  
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  */
}
/* ShareBtn */
.shareBtn, .shareBtn:hover {
  border: none;
  background: transparent;
  padding: 0;
}
.shareBtn {
position: absolute;
  bottom: 0;
  right: 0;
  margin: 20px;
}

.shareBtnIcon {
  display: block;
  width: 38px;
  height: 38px;
  position:  relative;
}

.headerImg .shareBtnIcon {
  display: none;
}
.btn {
  border-radius: 5px;
}

.headerImg {
  /* background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 210px; */
  text-align: center;
}
.locationImage {
  max-height: 282px!important;
}
.closeIcon {
  width: 30px;
  position: absolute;
  right: 10px;
  margin-top: 10px;
}

.deleteImage {
  font-size: 12px !important;
  line-height: 24px !important;
  width: 24px !important;
  height: 24px !important;
}
.fileContainer .uploadPictureContainer {
  width: 100% !important;
}
.fileContainer {
  padding:0 !important;
  box-shadow: none !important;
  margin: 0 !important;
}
.fileContainer p {
  text-align: center;
}
/* Card Type */
.card-topImg .img-fluid{
  border-radius: 5px 5px 0 0;
}
.card-topImg .card-body {
  background-color: #ffffff;
  border-radius: 0 0 5px 5px;
}
/* bgImg */
.card-bgImg {
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  
}
.card-bgImg h4, .card-bgImg p {
  color: #ffffff;
  text-shadow: 0px 0px 4px rgba(0,0,0,0.5);  
}


/* bgImgLrg */
.card-bgImgLrg {
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  
} 
.card-bgImgLrg h4 {
  color: #ffffff;
  text-shadow: 0px 0px 4px rgba(0,0,0,0.9);
  margin-bottom: 0;
}
.card-bgImgLrg p {
  color: #ffffff;
  text-shadow: 0px 0px 4px rgba(0,0,0,0.8);  
}
.card-bgImgLrg .card-body {
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 30%, rgba(0,0,0,0.8) 100%);
  border-radius: 5px;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.card-bgImgLrg.cardTextTop .card-body {
  background: none;
  /* background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 30%, rgba(0,0,0,0.8) 100%); */
  top: 0;
}

.card-bgImgLrg.cardTextTop .card-body .textBG {
  background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 30%, rgba(0,0,0,0.8) 100%);
  margin: -20px;
  padding:20px;
  border-radius: 5px;
}

.card-bgImgLrg .img-fluid{
  border-radius: 5px;
}
.card-bgImgLrg.cardTextTop a.external-link {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 20px;
}


/* bgImgLrg2 */

.card-bgImgLrg2 h4 {
  color: #ffffff;
  background-color: black;
  display: inline;
  padding: 0.5rem;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
.card-bgImgLrg2 p {
  color: #ffffff;
  background-color: black;
  display: inline;
  padding: 0.5rem;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
.card-bgImgLrg2 .card-body {
  border-radius: 5px;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.card-bgImgLrg2 .img-fluid{
  border-radius: 5px;
} 
.card-bgImgLrg2 a.external-link {
  margin-top: 15px;
}
.card-bgImgLrg2 .shareBtn{
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 20px;
} 

/* bgImgLrg2Top */
.card-bgImgLrg2.cardTextTop .card-body {
  top: 10px;
}
.card-bgImgLrg2.cardTextTop a.external-link {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 20px;
}




/* bgImg-Light + bgImgLrg-Light */
.card-bgImg-Light {
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  
}
.card-bgImg-Light h4, .card-bgImg-Light p, .card-bgImgLrg-Light h4, .card-bgImgLrg-Light p {
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5); 

}
.card-bgImgLrg-Light {
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  
}
.archivedEvents .dragIcon {
  display: none; 
}
.card-bgImg .card-body {
background: rgba(0,0,0,0.5);
border-radius: 5px;
}



.card-bgImg-Light .card-body {
  background: rgba(255, 255, 255, 0.7);
  border-radius: 5px;
}
.card-bgImgLrg-Light .card-body {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 58%, rgba(255, 255, 255, 0.5) 100%);
  padding-top: 195px;
  border-radius: 5px;
}

.claimRow {
  background: #000000;
}

.external-link {
  position: relative;
  /* color: #ffffff !important; */
}

/* Ad */
.ad img {
  border-radius: 5px;
  box-shadow: 4px 4px 10px rgba(0,0,0,0.4)!important;
  min-width: 100%;
  min-height: 100px;
}
/* Poll */

.card-poll h4 {
  font-size: 0.8rem;
  text-transform: uppercase;

  
}
.card-poll .poll-Question {
  font-size: 1.2rem;
  line-height: 1.3rem;
  margin-top: -5px;
}
.card-poll .alert {
  margin-bottom: 0;
}
.card-poll label {
  font-size: 1.1rem;
  padding: 5px;
}
.card-poll label input {
  width: 20px;
  height: 20px;
  margin-left: -30px;
}
.card-poll .shareBtn {
  display: none;
}

/* QuickLinks */
.quickLinks {
  border: 1px solid #eeeeee;
  border-radius: 5px;
  box-shadow: 4px 4px 10px rgba(0,0,0,0.2)!important;
  overflow: hidden;
  background: #ffffff;
    
}
.quick-link-card {
  height: 72px;
  padding: 10px 15px 0 10px;
  background: none !important;
  border: none !important;
  border-bottom: 1px solid #eeeeee !important;
}
.quick-link-card h4 {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 500;
}

.quick-link-card img {
  width: 50px;
  height: 50px;
  border-radius: 40px;
}

.quick-link-card a {
  margin-top: 10px;
}

.quick-link-card .media-body {
  padding: 6px 6px 6px 12px;
  font-size: 14px;
  line-height: 14px;
}

.quick-link-card .btn-sm {
  min-width: 80px;
}

/*
.external-link .spinner {
  width: 100%;
  height: auto;
  max-height: 100%;
  display: none;
  position: absolute;
  left: 0;
  top: 0;
}
*/

.leaderboard {
  text-align: center;
}

.leaderboard .self {
  background-color: #7400ae;
  color: #fff;
}

.eventEdit {
  margin-left: -15px;
  margin-right: -15px;
}

.eventEdit .card {
  width: 331px;
margin: auto;
}

.eventEdit ul li {
  padding: 15px;
}

.closeBtnTrivia {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 30px;
}

/* trivia overlay*/

.triviaOverlay .modal-content, .matchOverlay .modal-content {
  height: 90vh;
}
.triviaOverlay .modal-body, .matchOverlay .modal-body {
overflow: scroll;
    height: 60vh;
}

/* tooltips */
.tooltipicon {
  font-size: 140%;
  display: inline-block;
  vertical-align: middle;
  padding-left: 4px;
  padding-bottom: 4px;
  }